import { Box, styled, useTheme } from '@mui/material';
import { blue } from '@mui/material/colors';
import FeaturedProducts from './home/FeaturedProducts';
import Social from './home/Social';

function Home() {
  const theme = useTheme();

  const Home = styled(Box)({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    '& h1': {
      color: theme.palette.secondary.main,
      fontSize: '3rem',
      fontWeight: 700,
      textAlign: 'center',
      padding: '0 20px'
    },
    '& button:hover': {
      backgroundColor: blue[500]
    }
  });

  const Content = styled(Box)({
    maxWidth: '80%',
    padding: '50px',
    margin: 'auto',
    fontSize: '2rem',
    textAlign: 'center'
  });

  return (
    <Home>
      <Content>
        <br /> Welcome to our family owned muslim business. We&apos;re thrilled
        to have you join us for our delicious halal fusion desserts, ice cream,
        and boba. Come indulge in our unique flavors and experience the warmth
        of our family-friendly atmosphere. We can&apos;t wait to share our sweet
        creations with you!
      </Content>
      <FeaturedProducts />
      <Social />
    </Home>
  );
}

export default Home;
