import { Box, styled, useTheme } from '@mui/material';

function WeeklySpecials() {
  const theme = useTheme();

  const WeeklySpecials = styled(Box)({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    '& h1': {
      textAlign: 'center',
      color: theme.palette.secondary.main,
      fontSize: '3rem',
      fontWeight: 700
    }
  });

  const SpecialsList = styled(Box)({
    width: '80%',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& div': {
      margin: '10px',
      padding: '20px',
      '& a': {
        display: 'flex',
        justifyContent: 'center'
      }
    }
  });

  return (
    <WeeklySpecials>
      <h1>Weekly Specials</h1>
      <SpecialsList>
        {[
          {
            title: 'Carrot Cake',
            description: 'Carrot Cake',
            image: './images/carrot.jpg',
            link: 'https://www.toasttab.com/italian-express-glendale-heights-2041-bloomingdale-road'
          },
          {
            title: 'German Chocolate Cake',
            description: 'German Chocolate Cake',
            image: './images/german.jpg',
            link: 'https://www.toasttab.com/italian-express-glendale-heights-2041-bloomingdale-road'
          },
          {
            title: 'Gulab Jamun',
            description: 'Gulab Jamun',
            image: './images/gulab.jpg',
            link: 'https://www.toasttab.com/italian-express-glendale-heights-2041-bloomingdale-road'
          }
        ].map((special, index) => (
          <Box key={index} className="special">
            <a href={special.link} target="_blank" rel="noreferrer">
              <img
                src={special.image}
                alt={special.title}
                style={{
                  height: '300px',
                  width: '300px',
                  objectFit: 'cover'
                }}
              />
            </a>
            <p style={{ textAlign: 'center' }}>{special.description}</p>
          </Box>
        ))}
      </SpecialsList>
    </WeeklySpecials>
  );
}

export default WeeklySpecials;
