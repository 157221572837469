import { Box, Button, Link, styled, useTheme } from '@mui/material';

function FeaturedProducts() {
  const theme = useTheme();

  const FeaturedProducts = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& div': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      width: '250px',
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        width: 'initial'
      }
    },
    '& a': {
      margin: '20px 0'
    },
    '& button': {
      fontSize: 'large',
      padding: '15px 30px',
      borderRadius: '20px'
    }
  });

  const Image = styled('img')({
    height: '250px',
    width: '250px',
    objectFit: 'cover',
    margin: '30px 50px',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  });

  return (
    <FeaturedProducts>
      <h1>Featured Products</h1>
      <Box>
        <Image src="./images/brownie.jpg" alt="Brownie" />
        <Image src="./images/lotus.jpg" alt="Lotus Cheesecake" />
        <Image src="./images/chocolate.jpg" alt="Knafeh Chocolate" />
      </Box>
      <Box>
        <Link
          href="https://www.toasttab.com/italian-express-glendale-heights-2041-bloomingdale-road"
          underline="none"
          target="_blank"
          rel="noopener"
        >
          <Button variant="contained" color="primary">
            Shop All
          </Button>
        </Link>
      </Box>
    </FeaturedProducts>
  );
}

export default FeaturedProducts;
