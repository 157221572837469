import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Button,
  Link as MuiLink,
  SwipeableDrawer,
  styled,
  useTheme
} from '@mui/material';
import { blue, lightBlue } from '@mui/material/colors';
import React, { useState } from 'react';
import { Link as PageLink } from 'react-router-dom';

function Header() {
  const theme = useTheme();

  const [drawer, setDrawer] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setDrawer(open);
    };

  const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    paddingRight: '0',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '80%',
      padding: '20px 50px',
      margin: 'auto'
    },
    '& > div': {
      margin: 'initial',
      [theme.breakpoints.up('sm')]: {
        margin: 'auto'
      }
    }
  }));

  const Title = styled('h1')(({ theme }) => ({
    fontFamily: `'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif`,
    color: 'black',
    cursor: 'pointer',
    '& > span': {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'inline'
      }
    }
  }));

  const NavLinksList = styled('nav')({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'center',
    margin: '20px',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      margin: '0 20px'
    }
  });

  const PageNav = styled(PageLink)({
    margin: '10px',
    fontSize: 'large',
    textDecoration: 'none',
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      color: lightBlue[500]
    },
    [theme.breakpoints.up('sm')]: {
      margin: '0 10px'
    }
  });

  const NavElement = () => (
    <>
      <NavLinksList>
        <PageNav to="/products">Our Products</PageNav>
        <PageNav to="/about">About</PageNav>
        <PageNav to="/contact">Contact</PageNav>
      </NavLinksList>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <MuiLink
          href="https://www.toasttab.com/italian-express-glendale-heights-2041-bloomingdale-road"
          underline="none"
          target="_blank"
          rel="noopener"
        >
          <Button
            sx={{
              borderRadius: '20px',
              padding: '15px 30px',
              '&:hover': {
                backgroundColor: blue[500]
              }
            }}
            variant="contained"
          >
            Order
          </Button>
        </MuiLink>
      </Box>
    </>
  );

  return (
    <Box sx={{ backgroundColor: blue[100] }}>
      <Header>
        <Box>
          <PageLink to="/" style={{ display: 'flex', textDecoration: 'none' }}>
            <img
              src="./images/logo.png"
              alt="logo"
              style={{ height: '80px', margin: 'auto 10px' }}
            />
            <Title>
              {/* Ghosted Penguin */}
              <span> Bakery & Boba Shop</span>
            </Title>
          </PageLink>
        </Box>
        <Button
          sx={{
            display: { xs: 'flex', sm: 'none' },
            position: 'absolute',
            right: '0'
          }}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon sx={{ color: 'black' }} />
        </Button>
        <SwipeableDrawer
          anchor={'right'}
          open={drawer}
          onOpen={toggleDrawer(true)}
          onClose={toggleDrawer(false)}
        >
          <Box
            sx={{ padding: '40px 0' }}
            role="presentation"
            onClick={toggleDrawer(false)}
          >
            <NavElement />
          </Box>
        </SwipeableDrawer>
        <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
          <NavElement />
        </Box>
      </Header>
    </Box>
  );
}

export default Header;
