import { Box, styled, useTheme } from '@mui/material';
import WeeklySpecials from './products/WeeklySpecials';

function Products() {
  const theme = useTheme();

  const Products = styled(Box)({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column'
  });

  const Line = styled('hr')({
    margin: '0 auto 30px',
    width: '80%'
  });

  const Images = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '70%',
      margin: 'auto'
    }
  });

  const Image = styled('img')({
    margin: '10px',
    height: '125px',
    width: '125px',
    objectFit: 'cover',
    '@media (min-width: 460px)': {
      height: '200px',
      width: '200px'
    }
  });

  return (
    <>
      <WeeklySpecials />
      <Products>
        <Line />
        <Images>
          {[
            {
              name: 'Brownie',
              image: './images/brownie.jpg'
            },
            {
              name: 'Cake',
              image: './images/cake.jpg'
            },
            {
              name: 'Knafeh Chocolate',
              image: './images/chocolate.jpg'
            },
            {
              name: 'Cookie',
              image: './images/cookie.jpg'
            },
            {
              name: 'Raspberry Cookie',
              image: './images/cookie2.jpg'
            },
            {
              name: 'Kheer',
              image: './images/kheer.jpg'
            },
            {
              name: 'Kulfi',
              image: './images/kulfi.jpg'
            },
            {
              name: 'Lotus Cheesecake',
              image: './images/lotus.jpg'
            },
            {
              name: 'Smore Cookie',
              image: './images/smore.jpg'
            }
          ].map((product, index) => (
            <Image key={index} src={product.image} alt={product.name} />
          ))}
        </Images>
      </Products>
    </>
  );
}

export default Products;
