import { Box, Button, Link, styled } from '@mui/material';
import { InstagramEmbed } from 'react-social-media-embed';

function Social() {
  const Social = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  });

  const Wrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  });

  return (
    <Social>
      <h1>Follow us on social</h1>
      <Wrapper>
        <InstagramEmbed url="https://www.instagram.com/reel/C_jfN_ZO92E/" />
      </Wrapper>
      <Box sx={{ margin: '20px 0' }}>
        <Link
          href="https://www.instagram.com/ghostedpenguin/"
          underline="none"
          target="_blank"
          rel="noopener"
        >
          <Button
            variant="contained"
            color="primary"
            sx={{
              fontSize: 'large',
              padding: '15px 30px',
              borderRadius: '20px'
            }}
          >
            Social
          </Button>
        </Link>
      </Box>
    </Social>
  );
}

export default Social;
