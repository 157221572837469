import {
  Box,
  Button,
  FormControl,
  styled,
  TextField,
  useTheme
} from '@mui/material';

function Contact() {
  const theme = useTheme();

  const Contact = styled(Box)({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    },
    '& img': {
      margin: 'auto 0'
    }
  });

  const Content = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '80%',
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: '40%',
      margin: 'initial'
    },
    '& h1': {
      fontSize: '3rem',
      color: theme.palette.secondary.main
    }
  });

  const FormFields = styled(FormControl)({
    display: 'flex',
    width: '80%',
    '& > div': {
      margin: '10px'
    },
    '& button': {
      fontSize: '1rem',
      padding: '10px 20px',
      borderRadius: '10px',
      margin: '20px auto',
      width: '50%',
      alignItems: 'center'
    }
  });

  return (
    <Contact>
      <Content>
        <h1>Contact Us</h1>
        <FormFields>
          <TextField label="Name" />
          <TextField label="Email" />
          <TextField label="Message" multiline rows={3} />
          <p style={{ textAlign: 'center' }}>
            *Our contact form is currently disabled. Please email us directly at{' '}
            <a href="mailto:support@ghostedpenguin.com">
              support@ghostedpenguin.com
            </a>
          </p>
          <Button variant="contained" disabled>
            Submit
          </Button>
        </FormFields>
      </Content>
      <img
        style={{ display: 'none' }}
        src="https://placehold.co/400"
        alt="Contact"
      />
    </Contact>
  );
}

export default Contact;
