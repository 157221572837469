import { Outlet } from 'react-router-dom';
import Footer from './common/Footer';
import Header from './common/Header';

function PageLayout() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

export default PageLayout;
