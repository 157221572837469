import { Box, styled, useTheme } from '@mui/material';

function About() {
  const theme = useTheme();

  const About = styled(Box)({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column'
  });

  const Intro = styled(Box)({
    width: '80%',
    margin: '50px auto 0',
    padding: '20px',
    fontSize: '25px',
    border: 'black 1px solid'
  });

  const Bio = styled(Box)({
    width: '80%',
    margin: '50px auto 0',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      fontSize: '30px',
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row'
      }
    }
  });

  return (
    <About>
      <Intro>
        For over 40 years our family&apos;s Italian Express has been a beloved
        neighborhood spot, serving up delicious halal food and fostering a sense
        of community. It has been a journey of building relationships and
        earning your trust little by little. Now we embark on a new chapter,
        inspired by the love and loyalty you&apos;ve shown us. Join us as we
        introduce our latest venture: a cozy bakery and boba shop within Italian
        Express. This new addition is a heartfelt tribute to our loyal
        customers, offering a sweet escape and a complement to your favorite
        meals. We cannot wait to share our passion for freshly baked treats and
        handcrafted boba.
      </Intro>
      <Bio>
        <h1>Who We Are</h1>
        <Box>
          <p>
            Growing up, my brother and I were most likely to be found fighting
            over Wii controllers and Zabiha halal gyro pizza slices than
            creating a business together. But as we grew older we realized the
            legacy our family had built. Inspired by their dedication, we
            decided to join forces and now we only fight over kitchen space.
          </p>
          <img
            src="./images/amar_and_fatimah.jpg"
            alt="Fatimah & Amar"
            height={400}
            width={400}
          />
        </Box>
      </Bio>
      <Bio>
        <h1>What is a Ghosted Penguin?</h1>
        <Box>
          <p>
            Ghosty, as he was known online, is a man shrouded in mystery. He
            dreams of making a positive impact on the world and needed a name to
            reflect his stealthy aspirations.
            <br />
            <br />
            Enter me, a baker with an unwavering love of penguins. Penguins are
            known for their intense loyalty, a trait that deeply resonated with
            me. It is a quality we strive to emulate in our service to our
            customers.
            <br />
            <br />
            Inspired by Ghosty&apos;s enigmatic persona and the endearing
            loyalty shown by penguins, we created the name Ghosted Penguin. It
            is a symbol of commitment to providing exceptional service and
            anonymously changing the world.
          </p>
          <img
            style={{ display: 'none' }}
            src="https://placehold.co/400"
            alt="Penguin"
          />
        </Box>
      </Bio>
    </About>
  );
}

export default About;
