import { Box, styled, useTheme } from '@mui/material';

function Footer() {
  const theme = useTheme();

  const Footer = styled(Box)({
    backgroundColor: theme.palette.background.default,
    display: 'flex'
  });

  const Content = styled(Box)({
    width: '75%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    margin: 'auto',
    marginBottom: '30px',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      padding: '50px',
      justifyContent: 'initial',
      alignItems: 'initial',
      marginBottom: 'initial'
    },
    '& > div': {
      margin: '0 30px'
    },
    '& p': {
      margin: 0
    }
  });

  const Title = styled('h1')({
    color: theme.palette.secondary.main,
    fontSize: '2rem',
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      fontSize: '3rem',
      marginRight: 'auto'
    }
  });

  return (
    <Footer>
      <Content>
        <Title>Ghosted Penguin</Title>
        <Box>
          <h2>Location</h2>
          <a href="https://maps.app.goo.gl/WuRy2GDMCGpidxLz7">
            <p>2041 Bloomingdale Rd</p>
            <p>Glendale Heights, IL 60139</p>
          </a>
        </Box>
        <Box>
          <h2>Contact</h2>
          <p>
            <a href="mailto:support@ghostedpenguin.com">
              support@ghostedpenguin.com
            </a>
          </p>
          <p>
            <a href="tel:630-539-7600">(630) 539-7600</a>
          </p>
        </Box>
      </Content>
    </Footer>
  );
}

export default Footer;
