import { createTheme, ThemeProvider } from '@mui/material';
import { lightBlue } from '@mui/material/colors';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import PageLayout from './PageLayout';
import About from './pages/About';
import Contact from './pages/Contact';
import Error from './pages/Error';
import Home from './pages/Home';
import Products from './pages/Products';

const theme = createTheme({
  palette: {
    primary: {
      main: lightBlue[300]
    },
    secondary: {
      main: '#4f4f4f'
    },
    background: {
      default: lightBlue[50],
      paper: lightBlue[50]
    }
  }
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <PageLayout />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: 'products',
        element: <Products />
      },
      {
        path: 'about',
        element: <About />
      },
      {
        path: 'contact',
        element: <Contact />
      },
      {
        path: '*',
        element: <Error />
      }
    ]
  }
]);

/**
 * Colors:
 * - #ffffff header bg
 * - #f4f4f4 body bg
 * - #4f4f4f title text
 * - #7877e6 accent
 */
ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router}></RouterProvider>
    </ThemeProvider>
  </StrictMode>
);
