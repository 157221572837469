import { Box, styled, useTheme } from '@mui/material';

function Error() {
  const theme = useTheme();

  const Error = styled(Box)({
    backgroundColor: theme.palette.background.default
  });

  const Header = styled('h1')({
    textAlign: 'center',
    margin: 0,
    padding: '16px'
  });

  const Paragraph = styled('p')({
    textAlign: 'center',
    margin: 0,
    padding: '16px'
  });

  return (
    <Error>
      <Header>Invalid Page</Header>
      <Paragraph>We could not find the page you were looking for.</Paragraph>
    </Error>
  );
}

export default Error;
